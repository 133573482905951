<template>
  <!-- 安全管理配置 -->
  <div class="securityConfiguration-page">
    <template v-if="channelList.length">
      <el-tabs v-model="channelNo" type="card" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in channelList"
          :key="index"
          :label="item.channelName"
          :name="item.channelNo"
        >
        </el-tab-pane>
      </el-tabs>
      <div v-if="isShowData" class="content-box">
        <div>
          <span>是否要报班：</span>
          <el-switch
            v-model="params.isReport"
            :active-value="1"
            :inactive-value="0"
            inactive-color="#E3E3E3"
            active-color="#13ce66"
          >
          </el-switch>
        </div>
        <div>
          <span>检查证照有效期：</span>
          <el-switch
            v-model="params.isCheckLicenceValidity"
            :active-value="1"
            :inactive-value="0"
            inactive-color="#E3E3E3"
            active-color="#13ce66"
          >
          </el-switch>
        </div>
        <div>
          <span>检查车辆例检：</span>
          <el-switch
            v-model="params.isCheckVehicleInspection"
            :active-value="1"
            :inactive-value="0"
            inactive-color="#E3E3E3"
            active-color="#13ce66"
          >
          </el-switch>
        </div>
        <div style="width: 150px; text-align: end">
          <el-button
            v-if="listFind('保存')"
            type="primary"
            size="small"
            @click="saveFun"
            >保存</el-button
          >
        </div>
      </div>
      <div v-else class="empty-box">
        <img src="~@/assets/images/home/empty.png" alt />
        <div>暂无权限，请联系管理员</div>
      </div>
    </template>
    <div v-else class="empty-box">
      <img src="~@/assets/images/home/empty.png" alt />
      <div>暂无权限，请联系管理员</div>
    </div>
  </div>
</template>

<script>
import {
  getCurrentUserChannelInfoAPI,
  querySecurityManagementInfoAPI,
  updateSecurityManagementAPI,
} from "@/api/lib/api.js";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      isShowData: false,
      channelNo: null,
      channelName: null,
      channelList: [],
      params: {
        isReport: 0, //是否检查车辆例检 0-不检查 1-检查
        isCheckVehicleInspection: 0, //是否检查证照有效期 0-不检查 1-检查
        isCheckLicenceValidity: 0, //是否要报班 0-不需要 1-需要
      },
    };
  },
  filters: {},
  computed: {},
  watch: {},
  created() {
    this.getQueryChannelList();
  },
  mounted() {},
  methods: {
    /**
     * @description 获取系统配置-安全管理配置详情
     */
    getQuerySecurityManagementInfo() {
      querySecurityManagementInfoAPI({ channelNo: this.channelNo })
        .then((res) => {
          if (res.code == "SUCCESS") {
            this.params = JSON.parse(JSON.stringify(res.data));
            this.isShowData = true;
          } else {
            this.params.channelNo = this.channelNo;
          }
        })
        .catch(() => {
          this.params.channelNo = this.channelNo;
        });
    },
    /**
     * @description 保存
     */
    saveFun() {
      updateSecurityManagementAPI(this.params).then((res) => {
        if (res.code == "SUCCESS") {
          this.$message.success("保存成功");
          this.getQuerySecurityManagementInfo();
        }
      });
    },
    //点击渠道tab项
    handleClick(val) {
      this.channelNo = val.name;
      this.channelName = val.label;
      this.isShowData = false;
      this.getQuerySecurityManagementInfo();
    },
    //获取渠道数据
    getQueryChannelList() {
      getCurrentUserChannelInfoAPI()
        .then((res) => {
          if (res.code == "SUCCESS") {
            this.channelList = res.data || [];
            if (res.data.length > 0) {
              this.channelNo = this.channelList[0].channelNo;
              this.channelName = this.channelList[0].channelName;
            } else {
              this.$message.error("暂无权限，请联系管理员");
            }
          }
          this.getQuerySecurityManagementInfo();
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.securityConfiguration-page {
  width: 100%;
  height: 100%;
  background-color: #fff;
  .content-box {
    padding: 20px;
    font-size: 14px;
    & > div {
      margin-bottom: 20px;
      & > span {
        display: inline-block;
        width: 150px;
        text-align: end;
        margin-right: 40px;
      }
    }
  }
  .empty-box {
    width: 100%;
    font-size: 18px;
    text-align: center;
  }
}
</style>
